import * as React from "react";
import styled from "styled-components";

export function CallToAction({ text, onClick }) {
  return (
    <ActionButton 
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick();
        }
      }}
    >
      {text}
    </ActionButton>
  );
}

const ActionButton = styled.div`
  align-self: stretch;
  border-radius: 99px;
  background-color: rgba(2, 73, 95, 1);
  margin-top: 24px;
  width: 202px;
  max-width: 100%;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  padding: 20px 30px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: rgba(2, 73, 95, 0.9);
  }
  
  &:focus {
    outline: 2px solid #fff;
    outline-offset: 2px;
  }
  
  @media (max-width: 991px) {
    padding: 20px;
  }
`;