import * as React from "react";
import styled from "styled-components";
import { NavigationMenu } from "./NavigationMenu";
import logoImg from "../media/img/bbLogoClear.png";

export function NavigationBar() {
  return (
    <NavContainer id="home">
      <BrandLogo loading="lazy" src={logoImg} alt="Company Logo" />
      <NavSection>
        <NavigationMenu />
        {/* <Divider /> */}{" "}
      </NavSection>
    </NavContainer>
  );
}

const NavContainer = styled.nav`
  position: absolute;
  display: flex;
  width: 100%;
  max-width: 1240px;
  align-items: center;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
    gap: 0;
  justify-content: none;

  }
    @max-width: 510px {
    justify-content: none;
`;

const BrandLogo = styled.img`
  aspect-ratio: 2.4;
  object-fit: contain;
  object-position: center;
  width: 216px;
  align-self: stretch;
  margin: auto 0;

  @media (max-width: 951px) {
    width: 150px;
    height: 80px;
  }
  @media (max-width: 510px) {
    background-color: white;
    border-radius: 0 20%;
    position: relative;
    top: 1px;
    left: 10px;
    width: 45px;
    height: 40px;
  }
`;

const NavSection = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 29px;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 710px) {
    display: grid;
  }
`;

const Divider = styled.div`
  background-color: #fdfdfd;
  align-self: stretch;
  width: 0;
  height: 20px;
  margin: auto 0;
  border: 1px solid rgba(253, 253, 253, 1);
`;
