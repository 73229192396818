import * as React from "react";
import styled from "styled-components";

const socialIcons = [
  {
    id: 1,
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3aa700d0f039553d15c0c5a0f1dff13b29897419c120001dcf6e0d9e90af8f0c?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e",
    alt: "Social Media Icon 1",
  },
  {
    id: 2,
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0528bf9c2e932adbacd4d99ac1b9380ca7a541f67ff89478dd129880ad36e2ed?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e",
    alt: "Social Media Icon 2",
  },
];

export function SocialIcons() {
  return (
    <IconContainer>
      {socialIcons.map((icon) => (
        <SocialIcon
          key={icon.id}
          loading="lazy"
          src={icon.src}
          alt={icon.alt}
          tabIndex="0"
          role="button"
          onClick={(e) => {
            // if (e.key === "Enter") {
            // Social media link action here
            if (icon.id === 1) window.location.href = "tel:+1234567890";
            else if (icon.id === 2)
              window.location.href = "mailto:mab@bbglobalsolutions.com";
          }}
        />
      ))}
    </IconContainer>
  );
}

const IconContainer = styled.div`
  align-self: stretch;
  display: flex;
  align-items: start;
  gap: 10px;
  justify-content: end;
  margin: auto 0;
`;

const SocialIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 60px;
  cursor: pointer;
  &:focus {
    outline: 2px solid #fdfdfd;
    outline-offset: 2px;
  }
`;
