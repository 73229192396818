import * as React from "react";
import styled from "styled-components";
import { useForm, ValidationError } from "@formspree/react";

export function SubscriptionForm() {
  const [state, handleSubmit] = useForm("xoqbgjjn");
  if (state.succeeded) {
    return <FormAlert>Thanks for subscribing!</FormAlert>;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <label htmlFor="emailInput" className="visually-hidden">
        Your Email
      </label>
      <EmailInput type="email" id="emailInput" name="email" placeholder="Your email address"
      />
      <SubmitButton type="submit">Subscribe</SubmitButton>
    </Form>
  );
}

const FormAlert = styled.p`
  font-size: 2em;
  font-weight: bold;
  color: white;
  background-color: #f94c4c;
  padding: 1em;
  border-radius: 5% 0;
`;

const Form = styled.form`
  display: flex;
  margin-top: 20px;
  align-items: start;
  gap: 20px;
  text-align: center;
  justify-content: start;
  flex-wrap: wrap;
  font: 500 16px/1.6 Instrument Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`;

const EmailInput = styled.input`
  align-self: stretch;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  min-width: 240px;
  width: 421px;
  padding: 15px 26px;
  border: 1px solid rgba(255, 255, 255, 1);
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 15px 20px;
  }
`;

const SubmitButton = styled.button`
  align-self: stretch;
  border-radius: 10px;
  background-color: rgba(2, 73, 95, 1);
  color: rgba(255, 255, 255, 1);
  width: 172px;
  padding: 15px 25px;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 15px 20px;
  }
`;
