import * as React from "react";
import styled from "styled-components";

export function PageHeader() {
  return (
    <HeaderWrapper>
      <SubHeader>
        <HeaderIcon
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/fddd5ebf294fc131610e05bf0dae2f7e6a54635b30932dee55b18a459dd55a9f?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e"
          alt=""
        />
        <HeaderText>About Us</HeaderText>
      </SubHeader>
      <MainHeading>Why us?</MainHeading>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  max-width: 585px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SubHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  color: var(--Secondary, #232323);
  text-transform: uppercase;
  letter-spacing: 1.92px;
  line-height: 1;
`;

const HeaderIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
`;

const HeaderText = styled.div`
  align-self: stretch;
  margin: auto 0;
`;

const MainHeading = styled.h1`
  color: var(--Primary, #222);
  font-size: 48px;
  line-height: 1.4;
  margin-top: 11px;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;