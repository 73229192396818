import * as React from "react";
import styled from "styled-components";

export function SectionHeader() {
  return (
    <HeaderWrapper>
      <SubTitleWrapper>
        <Icon
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/fddd5ebf294fc131610e05bf0dae2f7e6a54635b30932dee55b18a459dd55a9f?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e"
          alt=""
        />
        <SubTitle>Reviews</SubTitle>
      </SubTitleWrapper>
      <Title>Happy Clients</Title>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  padding-left: 80px;
  padding-top: 80px;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  width: 585px;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  color: var(--Secondary, #232323);
  text-transform: uppercase;
  letter-spacing: 1.92px;
  line-height: 1;
`;

const Icon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 20px;
`;

const SubTitle = styled.span`
  font-family: Manrope, sans-serif;
`;

const Title = styled.h2`
  color: var(--Primary, #222);
  font-size: 48px;
  line-height: 1.4;
  margin-top: 11px;
  font-family: Manrope, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
    margin-left: -9rem;
  }
`;
