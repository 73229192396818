import * as React from "react";
import styled from "styled-components";

export function SocialIcons({ icons }) {
  return (
    <IconsContainer>
      {icons.map((icon, index) => (
        <SocialIcon
          key={index}
          loading="lazy"
          src={icon.src}
          alt={icon.alt}
        />
      ))}
    </IconsContainer>
  );
}

const IconsContainer = styled.div`
  align-self: start;
  display: flex;
  margin-top: 31px;
  align-items: start;
  gap: 10px;
  justify-content: start;
`;

const SocialIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  border-radius: 100px;
`;