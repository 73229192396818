import * as React from "react";
import styled from "styled-components";

export function FormInput({ id, label, type }) {
  return (
    <InputWrapper>
      <label htmlFor={id} className="visually-hidden">{label}</label>
      {type === "textarea" ? (
        <StyledTextarea
          id={id}
          name={id}
          placeholder={label}
          aria-label={label}
        />
      ) : (
        <StyledInput
          id={id}
          name={id}
          type={type}
          placeholder={label}
          aria-label={label}
        />
      )}
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid var(--Border-1, #dee2e5);
  background-color: #fff;
  margin-top: 30px;
  padding: 20px 17px;
  @media (max-width: 991px) {
    max-width: 100%;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  color: #5c6c7b;
  font: inherit;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  color: #5c6c7b;
  font: inherit;
  resize: vertical;
  min-height: 100px;
`;