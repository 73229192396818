import * as React from "react";
import styled from "styled-components";
import { ContactHeader } from "./ContactHeader";
import { ContactForm } from "./ContactForm";
import bgImg from "../media/img/worldWide.png";

export function Contact() {
  return (
    <ContactWrapper id="contact">
      <ContentContainer>
        <ContactHeader />
        <ContactForm />
      </ContentContainer>
    </ContactWrapper>
  );
}

const ContactWrapper = styled.div`
 background-image: url(${bgImg});
  background-size: cover;  /* This makes the image cover the entire div and zoom in if needed */
  background-position: center;  /* Center the image */
  background-repeat: no-repeat;  /* Ensures the image does not repeat */

  background-color: rgba(2, 73, 95, 1);
  overflow: hidden;
  padding: 127px 80px 0;
  @media (max-width: 991px) {
    padding: 100px 20px 0;
  }
`;

const ContentContainer = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;