import * as React from "react";
import styled from "styled-components";

export function DownloadButton(props) {
  return (
    <StyledButton 
      role="button" 
      href={props.link}
      target="_blank"
      tabIndex={0}
      // onClick={() => {}}
      // onKeyPress={(e) => {
      //   if (e.key === 'Enter' || e.key === ' ') {
      //     e.preventDefault();
      //   }
      // }}
    >
      Download
    </StyledButton>
  );
}

const StyledButton = styled.a`
  align-self: stretch;
  border-radius: 82px;
  background-color: rgba(2, 73, 95, 1);
  margin-top: 20px;
  width: 167px;
  max-width: 100%;
  gap: 8px;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1;
  padding: 17px 25px;
  cursor: pointer;
  user-select: none;

  &:focus {
    outline: 2px solid #000;
    outline-offset: 2px;
  }
`;