import * as React from "react";
import styled from "styled-components";
import { ReviewCard } from "./ReviewCard";
import { SectionHeader } from "./SectionHeader";
import { PaginationDots } from "./PaginationDots";
import arganiaScreen from "../media/img/arganiaScreenshot.png";
import socialrootsScreen from "../media/img/socialRootsScreenshot.png";
import nucorpoScreen from "../media/img/nucorpoScreenshot.png";
import cellfoodScreen from "../media/img/cellfoodScreenshot.png";
import bhuScreen from "../media/img/bhuScreenshot.png";
import impactiveScreen from "../media/img/impactiveScreenshot.png";
import elevateHTScreen from "../media/img/elevateHTscreenshot.png";
import seacretScreen from "../media/img/seacretScreenshot.png";

const testimonialData = [
  {
    imageSrc: arganiaScreen,
    type: "profile",
    name: "Argania",
  },
  {
    imageSrc: bhuScreen,
    type: "profile",
    name: "BHU",
  },
  {
    imageSrc: socialrootsScreen,
    type: "profile",
    name: "Social Roots",
  },
  {
    imageSrc: impactiveScreen,
    type: "profile",
    name: "Impactive",
  },
  {
    imageSrc: nucorpoScreen,
    type: "profile",
    name: "Nucorpo",
  },

  {
    imageSrc: elevateHTScreen,
    type: "profile",
    name: "ElevateHT",
  },
  {
    imageSrc: cellfoodScreen,
    type: "profile",
    name: "Cellfood",
  },
  {
    imageSrc: seacretScreen,
    type: "profile",
    name: "Seacret",
  },
];

export function Testimonials() {
  return (
    <>
      {" "}
      <div id="reviews">
        <SectionHeader />
        <TestimonialsWrapper>
          <ContentContainer>
            {/* <ActionButton tabIndex={0} role="button">
            See All
          </ActionButton> */}
          </ContentContainer>
          <CardsContainer>
            {testimonialData.map((item, index) => (
              <ReviewCard key={index} {...item} />
            ))}
          </CardsContainer>
        </TestimonialsWrapper>
        <PaginationDots activeIndex={0} count={3} />
      </div>
    </>
  );
}

const TestimonialsWrapper = styled.section`
  background: var(--White, #fff);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  align-items: start;
  padding: 100px 0 100px 80px;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;

  max-width: 1240px;
  align-items: center;
  gap: 40px 100px;
  font-family: Manrope, sans-serif;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  margin-top: 58px;
  align-items: center;
  gap: 19px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ActionButton = styled.button`
  border-radius: 99px;
  background-color: rgba(2, 73, 95, 1);
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  line-height: 1;
  width: 202px;
  padding: 20px 30px;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;
