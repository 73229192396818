import ServiceCard from "./ServiceCard";
import React, { useRef, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import "../App.css";

const servicesData = [
  {
    id: 1,
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d8c1f42e82fdd24250269831e8b71bf177fdbdeb9182c1a68a18effdb53628ab?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e",
    title: "Compensation Plans",
    description:
      "We are ready to design your multilevel compensation plan or rewrite your actual one. There is no such a thing as the Perfect Comp Plan for everyone. Working together towards the best fit for your company, your product, demographics, mission and goals. Best comp plan is not the most expensive anymore",
    variant: "blue",
  },
  {
    id: 2,
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d8c1f42e82fdd24250269831e8b71bf177fdbdeb9182c1a68a18effdb53628ab?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e",
    title: "Business Plan",
    description:
      "Thinking on going Direct Sales? We are here to help. Is your company/product suitable for a successful MLM model? Need help finding the right product, manufacturer or plan? Please Ask. We offer you all services necessary to help your company establish its direct or multi-level sales channel in the fastest, most focused and effective way possible.",
  },
  {
    id: 3,
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d8c1f42e82fdd24250269831e8b71bf177fdbdeb9182c1a68a18effdb53628ab?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e",
    title: "Go Global",
    description:
      "When is the right time to go global? Our experts will help you to decide.Expanding to a new country means some adaptations involving legal, commissions and operations. These could be small or big depending the market and country. We are here to help you on that. Please find more info about international numbers of Direct Sales",
  },
  {
    id: 4,
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d8c1f42e82fdd24250269831e8b71bf177fdbdeb9182c1a68a18effdb53628ab?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e",
    title: "MLM Software",
    description:
      "Thinking on going Direct Sales? We are here to help. Is your company/product suitable for a successful MLM model? Need help finding the right product, manufacturer or plan? Please Ask. We offer you all services necessary to help your company establish its direct or multi-level sales channel in the fastest, most focused and effective way possible.",
  },
  {
    id: 5,
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d8c1f42e82fdd24250269831e8b71bf177fdbdeb9182c1a68a18effdb53628ab?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e",
    title: "Best MLM Speakers",
    description:
      "In the world of traditional business, coaching is a relatively new development. However, direct selling and MLM companies have long used coaching as a method for personal development. We'll put you in contact with top coaches and speaker in the industry.",
  },
  {
    id: 6,
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d8c1f42e82fdd24250269831e8b71bf177fdbdeb9182c1a68a18effdb53628ab?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e",
    title: "Coach Sessions",
    description:
      "New in business? Already in business but looking for best results? Please ask for information about our tailored coaching sessions. A personalized session will help you build, launch or grow your ML or Direct Sales company, or accelerate the growth of your existing company while protecting it.",
  },
];

function Services() {
  const gridRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const gridPosition = gridRef.current.getBoundingClientRect().top;
      const screenPosition = window.innerHeight;

      if (gridPosition < screenPosition) {
        const cards = gridRef.current.querySelectorAll(".service-card");
        cards.forEach((card, index) => {
          card.style.animation = `floatIn 1s ease forwards ${index * 0.2}s`;
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <ServicesWrapper id="services">
      <Header>
        <TitleWrapper>
          <Icon
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/518c6eee96c39124b7a29ca5a3c147b481304ff8a623356a4438999677d98c04?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e"
            alt=""
          />
          <SectionLabel>Our Services</SectionLabel>
        </TitleWrapper>
        <SectionTitle>How can we help you</SectionTitle>
      </Header>
      <ServicesGrid ref={gridRef}>
        {servicesData.map((service) => (
          <ServiceCard key={service.id} {...service} />
        ))}
      </ServicesGrid>
    </ServicesWrapper>
  );
}

const ServicesWrapper = styled.section`
  background-color: rgba(2, 73, 95, 1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Manrope, sans-serif;
  padding: 102px 80px;

  @media (max-width: 991px) {
    padding: 100px 20px;
  }
`;

const Header = styled.div`
  display: flex;
  width: 585px;
  max-width: 100%;
  flex-direction: column;
  font-weight: 400;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  letter-spacing: 1.92px;
`;

const Icon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 20px;
`;

const SectionLabel = styled.span`
  color: #fff;
`;

const SectionTitle = styled.h2`
  color: #fff;
  font-size: 48px;
  line-height: 1.4;
  margin-top: 14px;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const ServicesGrid = styled.div`
  display: flex;
  margin-top: 49px;
  width: 100%;
  max-width: 1240px;
  align-items: start;
  gap: 24px 26px;
  line-height: 1.4;
  flex-wrap: wrap;
  align-self: center;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

export default Services;
