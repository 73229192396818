import * as React from "react";
import styled from "styled-components";

export function PaginationDots({ activeIndex, count }) {
  return (
    <DotsContainer>
      {[...Array(count)].map((_, index) => (
        <Dot key={index} $active={index === activeIndex} />
      ))}
    </DotsContainer>
  );
}

const DotsContainer = styled.div`
  display: flex;
  margin-top: 56px;
  align-items: center;
  gap: 5px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Dot = styled.div`
  background-color: ${props => props.$active ? 'rgba(2, 73, 95, 1)' : 'rgba(217, 217, 217, 1)'};
  border-radius: 50%;
  width: 8px;
  height: 8px;
`;