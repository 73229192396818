import React from "react";
import styled from "styled-components";
function ServiceCard({ icon, title, description, variant }) {
  return (
    <Card className="service-card" $variant={variant}>
      <CardIcon src={icon} alt="" loading="lazy" />
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
        {/* <LearnMoreButton>Learn More</LearnMoreButton> */}
      </CardContent>
    </Card>
  );
}

const Card = styled.article`
  justify-content: center;
  border-radius: 15px;
  border: 1px solid var(--Variant-Background, #ddeade);
  background-color: #ccebff;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  width: 317px;
  padding: 40px;
  transform: translateX(100%) rotate(360deg);
  &:hover {
    background-color: #ffff; /* Color change on hover */
  }
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const CardIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 50px;
  border-radius: 99px;
  background-color: white;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.h3`
  color: var(--Primary, #222);
  font-size: 24px;
  font-weight: 500;
`;

const CardDescription = styled.p`
  color: var(--Text, #909090);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 14px;
`;

const LearnMoreButton = styled.button`
  color: rgba(2, 73, 95, 1);
  font-size: 18px;
  font-weight: 600;
  margin-top: 14px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 2px solid rgba(2, 73, 95, 1);
    outline-offset: 2px;
  }
`;

export default ServiceCard;
