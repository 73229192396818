import * as React from "react";
import styled from "styled-components";
import { DocsTitle } from "./DocsTitle";
import { DocsContainer } from "./DocsContainer";

export function Docs() {
  return (
    <>
      <DocsMainContainer>
        <HeaderContainer>
          <DocsTitle />
          {/* <ActionButton tabIndex={0} role="button">See All</ActionButton> */}
        </HeaderContainer>
        <DocsContainer />
      </DocsMainContainer>
    </>
  );
}


const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px 100px;
  font-family: Manrope, sans-serif;
  font-weight: 400;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const DocsMainContainer = styled.section`
  display: grid;
  position: relative;
  top: -10rem;
  align-items: center;
`;
const ActionButton = styled.div`
  align-self: stretch;
  border-radius: 99px;
  background-color: rgba(2, 73, 95, 1);
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  line-height: 1;
  width: 202px;
  margin: auto 0;
  padding: 20px 30px;
  cursor: pointer;

  &:focus {
    outline: 2px solid #4a90e2;
    outline-offset: 2px;
  }

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;
