import * as React from "react";
import styled from "styled-components";

export function HeroHeading({ mainText, tagline }) {
  return (
    <HeadingWrapper>
      <MainHeading>{mainText}</MainHeading>
      <Tagline>{tagline}</Tagline>
    </HeadingWrapper>
  );
}

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--White, #fff);
  font-weight: 700;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const MainHeading = styled.h1`
  font-size: 75px;
  margin: 0;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
  @media (max-width: 428px) {
    font-size: 35px;
  }
  @media (max-width: 368px) {
    font-size: 30px;
  }
`;

const Tagline = styled.h2`
  font-size: 64px;
  margin: 0;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
  @media (max-width: 428px) {
    font-size: 35px;
  }
  @media (max-width: 368px) {
    font-size: 30px;
  }
`;
