import * as React from "react";
import styled from "styled-components";

export function ReviewCard({ type, imageSrc, name }) {
  if (type === "profile") {
    return (
      <ProfileCard>
        <ProfileImageWrapper>
          <ProfileImage>
            <BackgroundImage loading="lazy" src={imageSrc} alt="" />
            <ProfileCircle>
              <NameWrapper>
                <Name>{name}</Name>
              </NameWrapper>
            </ProfileCircle>
          </ProfileImage>
        </ProfileImageWrapper>
      </ProfileCard>
    );
  }

  return (
    <CardWrapper>
      <CardImage loading="lazy" src={imageSrc} alt="" />
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  width: 555px;
  margin: auto 0;
  background-color: #85b5e22b;
  border-radius: 1%;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CardImage = styled.img`
  aspect-ratio: ${(props) => (props.src.includes("ext_4") ? "0.6" : "1.75")};
  object-fit: contain;
  width: ${(props) => (props.src.includes("ext_4") ? "192px" : "555px")};
  max-width: 100%;
`;

const ProfileCard = styled(CardWrapper)`
  color: var(--White, #fff);
  font: 500 24px/1.4 Manrope, sans-serif;
`;

const ProfileImageWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  background: var(--Secondary, #232323);
  display: flex;
  max-width: 100%;
  width: 555px;
  flex-direction: column;
  overflow: hidden;
`;

const ProfileImage = styled.div`
  display: flex;
  position: relative;
  min-height: 318px;
  width: 100%;
  align-items: flex-end;
  padding: 80px 56px 0;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ProfileCircle = styled.div`
  position: relative;
  border-radius: 50%;
  display: flex;
  width: 238px;
  flex-direction: column;
  align-items: flex-end;
  aspect-ratio: 1;
  padding: 88px 48px 0;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const NameWrapper = styled.div`
    width: 125%;
    background: linear-gradient(45deg, rgba(2, 73, 95, 1), transparent);
    border-radius: 5% 0;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Name = styled.span`
  font-family: Manrope, sans-serif;
`;
