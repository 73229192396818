import * as React from "react";
import styled from "styled-components";

function WorldWideFilter() {
  return (
    <StyledImage
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/42b49d47c37b7a5e983546e6f2375407d56d47feedbc03027ce3cbf76c258e45?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e"
      alt="Hero banner"
      tabIndex="0"
    />
  );
}

const StyledImage = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default WorldWideFilter;
