import * as React from "react";
import styled from "styled-components";
import { SubscriptionForm } from "./SubscriptionForm";
import { SectionHeader } from "./SectionHeader";

export default function Newsletter() {
  return (
    <NewsletterContainer>
      <SectionHeader />
      <SubscriptionForm />
    </NewsletterContainer>
  );
}

const NewsletterContainer = styled.div`
  position: relative;
  /* align-self: center; */
  justify-self: center;
  top: -24rem;
  display: flex;
  max-width: 613px;
  flex-direction: column;
  justify-content: start;
`;
