import * as React from "react";
import styled from "styled-components";
import { FormInput } from "./FormInput";
import { useForm, ValidationError } from "@formspree/react";

export function ContactForm() {
  const formInputs = [
    { id: "name", label: "Your Name", type: "text" },
    { id: "email", label: "Your Email Address", type: "email" },
    { id: "message", label: "Your Message...", type: "textarea" },
  ];

  const [state, handleSubmit] = useForm("xoqbgjjn");
  if (state.succeeded) {
    return <FormAlert>Thanks, we will contact you soon!</FormAlert>;
  }

  return (
    <FormContainer>
      <FormWrapper onSubmit={handleSubmit}>
        <FormTitle>
          Contact Us Now to Get Free Quote from Our Experts!
        </FormTitle>
        {formInputs.map((input) => (
          <FormInput key={input.id} {...input} />
        ))}
        <SubmitButton type="submit">Get a Free Quote!</SubmitButton>
      </FormWrapper>
    </FormContainer>
  );
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const FormWrapper = styled.form`
  border-radius: 10px 10px 0 0;
  background-color: rgb(0 0 0 / 49%);
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--Text, #5c6c7b);
  margin: 13px auto 0;
  padding: 58px 41px 94px;
  font: 400 16px/1.6 Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const FormTitle = styled.h2`
  color: var(--Main-Background, #f1f4ff);
  text-align: center;
  align-self: center;
  font: 600 24px/34px Manrope, sans-serif;
`;

const SubmitButton = styled.button`
  border-radius: 10px;
  background-color: rgba(2, 73, 95, 1);
  margin-top: 30px;
  min-height: 61px;
  font-size: 15px;
  color: var(--White, #fff);
  font-weight: 600;
  text-align: center;
  line-height: 1;
  padding: 25px 40px;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const FormAlert = styled.p`
  font-size: 2em;
  font-weight: bold;
  color: white;
  background-color: #f94c4c;
  padding: 1em;
  border-radius: 5% 0;
`;
