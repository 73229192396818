import * as React from "react";
import styled from "styled-components";
import { DownloadButton } from "./DownloadButton";

export function DocsCard(props) {
  return (
    <PreviewCard>
      <PreviewContent>
        <DocumentTitle>{props.title}</DocumentTitle>
        <DownloadButton link={props.link}/>
      </PreviewContent>
    </PreviewCard>
  );
}

const PreviewCard = styled.div`
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.1);
  display: flex;
  max-width: 352px;
  flex-direction: column;
  align-items: start;
  font-family: Manrope, sans-serif;
  justify-content: center;
  padding: 32px 26px;
  border: 1px solid rgba(181, 181, 181, 1);
`;

const PreviewContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 284px;
  flex-direction: column;
  justify-content: start;
`;

const DocumentTitle = styled.h2`
  color: var(--Primary, #222);
  font-size: 22px;
  font-weight: 500;
  line-height: 31px;
  margin: 0;
`;
