import React from "react";
import styled from "styled-components";
import { ReactComponent as HamburgerSvg } from "../media/img/hamburger-logo.svg";

const SvgIcon = styled(HamburgerSvg)`
  width: 30px;
  height: 30px;
  cursor: pointer;

  @media (min-width: 501px) {
    display: none;
  }
`;
const SvgContainer = styled.div`
  position: relative;
  top: 1rem;
  right: -12rem;
  width: 1rem;

  @media (min-width: 501px) {
    display: none;
  }

`;
const HamburgerIcon = ({ toggleMenu }) => (
  <SvgContainer>
    <SvgIcon onClick={toggleMenu} />
  </SvgContainer>
);

export default HamburgerIcon;
