import * as React from "react";
import styled from "styled-components";

export function ContactPhone({ phoneNumber, phoneLabel }) {
  return (
    <PhoneWrapper>
      <PhoneLabel>{phoneLabel}</PhoneLabel>
      <PhoneLink href={`tel:${phoneNumber.replace(/\D/g,'')}`}>
        {phoneNumber}
      </PhoneLink>
    </PhoneWrapper>
  );
}

const PhoneWrapper = styled.div`
  letter-spacing: 1.92px;
  text-transform: uppercase;
  font: 400 16px/16px Marcellus, sans-serif;
  color: var(--White, #fff);
`;

const PhoneLabel = styled.span`
  font-family: Manrope, sans-serif;
`;

const PhoneLink = styled.a`
  font-family: Manrope, sans-serif;
  text-decoration: underline;
  color: inherit;
`;