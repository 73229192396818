import * as React from "react";
import styled from "styled-components";
import { FooterColumn } from "./FooterColumn";
import { SocialIcons } from "./SocialIcons";
import { FooterLinks } from "./FooterLinks";
import { CopyrightSection } from "./CopyrightSection";
import logoImg from "../media/img/bbLogoClear.png";

const footerLinksData = [
  {
    title: "Company",
    links: [{index: 1, linkName: "Home", link: "#navbar"}, {index: 2, linkName:"About Us", link: "#navbar"}, {index: 1, linkName:"Courses", link: "#navbar"}, {index: 1, linkName:"Programs", link: "#navbar"}]
  },

];

const socialIcons = [
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/cd175c236b1f9ac09c6e48cf41bd0ab77f16506d3c52717659040658f62b7193?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e", alt: "Facebook" },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b23112afb648d0422bc908ce4a44c16b3db142212ee72ea7ad64ae2e2cc288c2?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e", alt: "Twitter" },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/ebdb8528bf6e6637530d4bc0c9427738af79601ea473955891eada787f9e12bd?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e", alt: "Instagram" },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/67a3aee2afd67a4930aaf83015aa9e2a49b1c14b89e40e0ddb1368931ee576ff?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e", alt: "LinkedIn" }
];

export function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterGrid>
          <FooterColumn>
          <LogoImage
              loading="lazy"
              src={logoImg}
              alt="Company Logo"
            />
            <LogoContainer>
          
            </LogoContainer>
         
            <ContactSection>
              <Description>
             "B&BGlobalSolutions, sells time."<br/> Time you save at improving your business, time ahead of market trends and your competitors, and time for you to enjoy doing whatever the hell you decide to do with it.

              </Description>
              <SocialIcons icons={socialIcons} />
            </ContactSection>
          </FooterColumn>
          <LinksColumn>
            {/* <LinksGrid>
              {footerLinksData.map((section, index) => (
                <FooterLinks key={section.index} title={section.title} links={section.links} />
              ))}
            </LinksGrid> */}
          </LinksColumn>
        </FooterGrid>
      </FooterContent>
      <Divider />
      <CopyrightSection />
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  background-color: rgba(2, 73, 95, 1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  padding: 47px 80px 30px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const FooterContent = styled.div`
  width: 100%;
  max-width: 1164px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const LogoContainer = styled.div`
  width: 70%%;
  align-self: center;
  background-color:#fff;
    border-radius: 50%;
`;
const FooterGrid = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const LogoImage = styled.img`
    aspect-ratio: 2.4;
    margin-left: 40%;
    object-fit: contain;
    object-position: left;
    width: 216px;
    max-width: 100%;
    background-color: white;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
`;

const ContactSection = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const Description = styled.p`
  color: #fff;
  font: 400 16px/26px Manrope, sans-serif;
`;

const LinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 60%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const LinksGrid = styled.div`
  display: flex;
  margin-top: 17px;
  align-items: center;
  gap: 40px 155px;
  font-family: Manrope, sans-serif;
  color: #fff;
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Divider = styled.hr`
  background-color: #f0ffd9;
  margin-top: 47px;
  width: 1240px;
  max-width: 100%;
  height: 1px;
  border: 1px solid rgba(240, 255, 217, 1);
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;