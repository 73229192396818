import * as React from "react";
import styled from "styled-components";

export function CopyrightSection() {
  return (
    <CopyrightContainer>
      <CopyrightText>
        <Text>Copyright © 2025 B&B Global Solutions Inc - All Rights Reserved.</Text>
        <Separator />
      </CopyrightText>
      <LegalLinks>
        <Link>Terms of Use</Link>
        <Separator />
        <Link>Privacy Policy</Link>
      </LegalLinks>
    </CopyrightContainer>
  );
}

const CopyrightContainer = styled.div`
  display: flex;
  margin-top: 18px;
  width: 100%;
  max-width: 1140px;
  align-items: start;
  gap: 40px 100px;
  color: #fff;
  justify-content: space-between;
  flex-wrap: wrap;
  font: 400 14px/1.6 Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CopyrightText = styled.div`
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 20px;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Text = styled.span`
  align-self: stretch;
  margin: auto 0;
`;

const LegalLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: start;
`;

const Link = styled.a`
  align-self: stretch;
  margin: auto 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Separator = styled.div`
  align-self: stretch;
  width: 0;
  height: 10px;
  margin: auto 0;
  border: 1px solid rgba(255, 255, 255, 1);
`;