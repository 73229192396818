import * as React from "react";
import styled from "styled-components";
import { InfoCard } from "./InfoCard";
import { PageHeader } from "./PageHeader";

const cardData = [
  {
    title: "Sell Direct",
    content: `Direct selling is a retail channel used by top global brands and smaller, entrepreneurial companies to market products and services to consumers. Companies market all types of goods and services, including jewelry, cookware, nutritionals, cosmetics, housewares, energy and insurance, and much more.\n\nThe direct selling channel differs from broader retail in an important way. It isn't only about getting great products and services into consumers' hands. It's also an avenue where entrepreneurial-minded people can work independently to build a business with low start-up and overhead costs.\n\nIt is a Global industry with sales of $189,641 (USD millions), Up 1.6% in Constant 2017 USD (source WFDSA)`
  },
  {
    title: "Our mission",
    content: `At B&B Global Solutions, our mission is to help entrepreneurs to create a Direct Sales or MLM business. Also, to assist new or existing companies based on traditional sales systems to reorient themselves toward the creation of Sales Networks, both through Direct Sales structures as well as through Multi-Level or Network Marketing structures, eliminating unnecessary middlemen and thus, approaching the client directly. marketing consultant #mlmconsultants work at home`
  }
];

export  function AboutUs() {
  return (
    <PageContainer id="about">
      <ContentWrapper>
        <div>
        <PageHeader />
        <MainText>
          Obtain totally personalized service.
          <br />- Work with a highly-specialized team with exceptional experience.
          <br />- Acquire the potential to increase your sales, both forcefully and dynamically.
          <br />- Establish a work model focused on injecting your product into the market.
          <br />- A sales system adapted for your company.
          <br />- A 100% efficient solution for your company's growth.
          <br />- We are a group of consultants specializing in helping small and medium size enterprises to sell their products through direct and multi-level sales channels.
          <br />- Our team of experts is distinguished in each related specialty to achieve this end: SELL MORE, SELL BETTER, SELL DIRECT
          <br />- Our clients are enterprises with unique products that wish to market them while eliminating middlemen.
          <br />- We have extensive experience and work teams in Europe, the USA, Asia and Latin America.
          <br />- Our management team is comprised of professionals with extensive experience in the world's major MLM companies.
        </MainText>
        </div>
        <CardsContainer>
          {cardData.map((card, index) => (
            <InfoCard key={index} title={card.title} content={card.content} />
          ))}
        </CardsContainer>
      </ContentWrapper>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  justify-content: center;
  align-items: center;
  background: var(--White, #fff);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Manrope, sans-serif;
  padding: 91px 70px;
  @media (max-width: 991px) {
    padding: 20px 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px 75px;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const MainText = styled.div`
  color: var(--Text, #909090);
  font-size: 16px;
  line-height: 26px;
  margin-top: 11px;
  max-width: 585px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 580px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;