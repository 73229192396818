import React from "react";
import styled, { keyframes } from "styled-components";
import colgateLogo from "../media/img/colgateLogobb.png";
import amfamLogo from "../media/img/amfamLogobb.png";
import palmoliveLogo from "../media/img/palmoliveLogobb.png";
import seacretLogo from "../media/img/seacretLogobb.png";
import classDojLogo from "../media/img/classDojLogobbW.png";
import impactiveLogo from "../media/img/impactivebb.png";
import bioplusLogo from "../media/img/bioplusLogobb.png";
import pmInterLogo from "../media/img/pmInterbb.png";
import socialrootsLogo from "../media/img/socialrootsbb.png";
import elevateHTLogo from "../media/img/elevateHTbb.png";
import patternAgLogo from "../media/img/patternAgbb.png";
import zinzinoLogo from "../media/img/zinzinoLogobb.png";

const scroll = keyframes`
  0% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(-105%);
  }
`;

const StripContainer = styled.div`
  position: absolute;
  top: 40%;
  left: -1rem;
  width: 101.5%;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transform: rotate(3deg);
  background-color: rgb(14 72 94);
  opacity: 0.8;

  @media (max-width: 430px) {
    width: 104%;
    top: 22rem;
  }
      @media (max-width: 739px) {
    width: 104%;
    top: 22rem;
  }

  @media (min-width: 740px) {
    top: 30.5rem;
  }

  @media (min-width: 830px) {
    top: 32rem;
  }

  @media (min-width: 950px) {
    top: 40rem;
  }
    @media (min-width: 1100px) {
    top: 50rem;
}
  @media (min-width: 1400px) {
    top: 52rem;
    -webkit-transform: rotate(0deg);
  }

  @media (min-width: 1800px) {
  top:60rem;
`;

const LogosWrapper = styled.div`
  display: inline-block;
  padding: 10px 0;
  animation: ${scroll} 30s linear infinite;
`;

const Logo = styled.img`
  height: 50px;
  margin: 0 25px;
`;

const logos = [
  colgateLogo,
  amfamLogo,
  palmoliveLogo,
  seacretLogo,
  classDojLogo,
  impactiveLogo,
  bioplusLogo,
  pmInterLogo,
  socialrootsLogo,
  elevateHTLogo,
  patternAgLogo,
  zinzinoLogo,
  // Add more logo paths here
];

const LogoStrip = () => {
  return (
    <StripContainer>
      <LogosWrapper>
        {logos.map((logo, index) => (
          <Logo src={logo} alt={`Logo ${index + 1}`} key={index} />
        ))}
      </LogosWrapper>
    </StripContainer>
  );
};

export default LogoStrip;
