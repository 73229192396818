import logo from "./logo.svg";
import "./App.css";
import { NavigationBar } from "./NavigationBar";
import { HeroImage } from "./HeroImage";
import { LandingHero } from "./LandingHero";
import { AboutUs } from "./AboutUs";
import Services from "./Services";
import {Testimonials} from "./Testimonials";
import {Contact} from "./Contact";
import { Footer} from "./Footer"
import WorldWideFilter from "./WorldWideFilter";
import EmailHero from "./EmailHero";
import Newsletter from "./Newsletter";
import LogoStrip from "./LogoStrip";
import {Docs} from "./Docs"

function App() {
  return (
    <div className="App">
      <div>
        <NavigationBar />
        <HeroImage />
        <LogoStrip />
        <LandingHero />
        <AboutUs />
        <Services />
        <Testimonials />
        
        <Contact />
        <EmailHero />
        <Newsletter />
        <Docs />
        <Footer />

      </div>
    </div>
  );
}

export default App;
