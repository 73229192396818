import styled from "styled-components";

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 40%;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
`;