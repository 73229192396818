import * as React from "react";
import styled from "styled-components";

export function SectionHeader() {
  return (
    <HeaderWrapper>
      <HeaderTitle>Subscribe</HeaderTitle>
      <HeaderDescription>
        Sign up to hear from us about specials, sales, and events.
      </HeaderDescription>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--Primary-White, #fff);
  font-weight: 400;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeaderTitle = styled.h2`
  align-self: center;
  gap: 10px;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 3.11px;
  font: 16px Satoshi, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const HeaderDescription = styled.p`
  text-align: center;
  margin-top: 10px;
  font: 41px/50px Sora, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;