import * as React from "react";
import styled from "styled-components";

export const HeroImage = () => {
  return (
    <StyledImage
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a038f840e6cdfbdbc8a63e98e29d8cd1a2c4f9c18c6af202b5ad521a1ac4165?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e"
      alt="Hero banner"
    />
  );
};

const StyledImage = styled.img`
  aspect-ratio: 1.41;
  object-fit: contain;
  object-position: center;
  width: 100%;
  display: block;
  max-width: 100%;
  height: auto;
  @media (max-width: 560px) {
    aspect-ratio: 1.41;
    object-fit: cover;
    object-position: center;
    width: 100%;
    display: block;
    max-width: 100%;
    height: 420px;
  }

  @media (max-width: 360px) {
    min-height: 500px;
  }
`;

export default HeroImage;
