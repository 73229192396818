import React, { useState } from "react";
import styled from "styled-components";
import HamburgerIcon from "./HamburgerIcon";
import { SocialIcons } from "./SocialIcons";

const menuItems = [
  { id: 1, label: "About Us", link: "about" },
  { id: 2, label: "Services", link: "services" },
  { id: 3, label: "Reviews", link: "reviews" },
  { id: 4, label: "Contact Us", link: "contact" },
];

// const HamburgerIcon = styled.svg`
//   display: none;
//   cursor: pointer;
//   @media (max-width: 500px) {
//     display: block;
//   }

//   div {
//     width: 25px;
//     height: 3px;
//     background-color: white;
//     margin: 5px 0;
//   }
// `;

export function NavigationMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <HamburgerIcon toggleMenu={toggleMenu} />
      <MenuContainer isOpen={isOpen}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.id}
            tabIndex="0"
            role="button"
            onClick={() => scrollToSection(item.link)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                window.location.href = "tel:+3135098414";
              }
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </MenuContainer>
      {isOpen ? "" : <SocialIcons />}
    </>
  );
}

const MenuContainer = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 16px;
  color: var(--Light-Gray-1, #fdfdfd);
  text-transform: uppercase;
  justify-content: start;
  margin: auto 0;
  font: 400 16px/1 Manrope, sans-serif;
  @media (max-width: 600px) {
    align-self: stretch;
    display: grid;
    min-width: 240px;
    align-items: end;
    gap: 16px;
    color: var(--Light-Gray-1, #fdfdfd);
    text-transform: uppercase;
    justify-content: end;
    margin: auto 0;
    font: 400 16px / 1 Manrope, sans-serif;
  }
  @media (max-width: 510px) {
    display: ${({ isOpen }) => (isOpen ? "grid" : "none")};
    position: absolute;
    top: 4rem;
  }
`;

const MenuItem = styled.div`
  align-self: stretch;
  margin: auto 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: 2px solid #fdfdfd;
    outline-offset: 2px;
  }
`;
