import * as React from "react";
import styled from "styled-components";

export function ContactHeader() {
  return (
    <HeaderContainer>
      <HeaderContent>
        <TitleSection>
          <SubtitleWrapper>
            <Icon
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/518c6eee96c39124b7a29ca5a3c147b481304ff8a623356a4438999677d98c04?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e"
              alt="Contact icon"
            />
            <SubtitleText>Contact Us</SubtitleText>
          </SubtitleWrapper>
          <MainTitle>Get in Touch with us</MainTitle>
        </TitleSection>
        <HeaderImage
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/15a52c029352744508bb895d179121206cf6d8c12423bbcad7fda88df41e8f36?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e"
          alt="Contact illustration"
        />
      </HeaderContent>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
  
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Manrope, sans-serif;
  font-weight: 400;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SubtitleWrapper = styled.div`
  align-self: start;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  letter-spacing: 1.92px;
  line-height: 1;
  justify-content: start;
`;

const Icon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
`;

const SubtitleText = styled.div`
  align-self: stretch;
  margin: auto 0;
`;

const MainTitle = styled.h1`
  color: #fff;
  font-size: 48px;
  line-height: 1.4;
  margin-top: 14px;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const HeaderImage = styled.img`
  aspect-ratio: 1.57;
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 20px;
  margin-top: 81px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 40px 7px 0 0;
  }
`;