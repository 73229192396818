import * as React from "react";
import styled from "styled-components";

export const EmailHero = () => {
  return (
    <StyledEmailHero
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/fb12eb95a76b2af48cc35946752f2c4b8ecabf1b26f1e62ede98dfebaa6254ac?placeholderIfAbsent=true&apiKey=af3b926ef0c2405e9a702a855cd6e35e"
      alt="Hero banner"
    />
  );
};

const StyledEmailHero = styled.img`
  aspect-ratio: 2.67;
  object-fit: contain;
  object-position: center;
  width: 100%;
  display: block;
@media (max-width: 1000px) {
    aspect-ratio: 2.67;
    object-fit: cover;
    object-position: center;
    width: 100%;
    display: block;
    height: 500px;
}
`;

export default EmailHero;
