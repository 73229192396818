import * as React from "react";
import styled from "styled-components";

export function InfoCard({ title, content }) {
  return (
    <CardContainer>
      <CardTitle>{title}</CardTitle>
      <CardContent>{content}</CardContent>
    </CardContainer>
  );
}

const CardContainer = styled.div`
  border-radius: 15px;
  border: 1px solid var(--Soft-Green, #f0ffd9);
  background-color: #ccebff;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 30px;

  &:hover {
    background-color: #ffffff; /* Color change on hover */
    border: 1px solid rgb(47, 51, 42);

  }

  @media (max-width: 991px) {
    padding: 30px 20px;
  }
`;

const CardTitle = styled.h2`
  color: var(--Primary, #222);
  font-size: 34px;
  font-weight: 500;
  line-height: 1.4;
`;

const CardContent = styled.div`
  color: var(--Text, #909090);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
