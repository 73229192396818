import * as React from "react";
import styled from "styled-components";
import { ContactPhone } from "./ContactPhone";
import { CallToAction } from "./CallToAction";
import { HeroHeading } from "./HeroHeading";

export function LandingHero() {
  return (
    <HeroContainer>
      <HeroContent>
        <ContactPhone phoneNumber="313-509-8414" phoneLabel="PHONE:" />
        <HeroHeading
          mainText="Sell More, Sell Direct"
          tagline="#mlmconsultants"
        />
        {/* <CallToAction text="Learn More" onClick={() => {}} /> */}
      </HeroContent>
    </HeroContainer>
  );
}

const HeroContainer = styled.section`
  position: absolute;
  top: 30rem;
  left: -10rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1100px) {
    top: 26rem;
    left: -7rem;
  }
  @media (max-width: 996px) {
    top: 23rem;
    left: 1rem;
  }
  @media (max-width: 750px) {
    top: 13rem;
    left: -1rem;
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Manrope, sans-serif;
  justify-content: start;
`;
