import * as React from "react";
import styled from "styled-components";

export function FooterLinks({ title, links }) {
  return (
    <LinksSection>
      {/* <LinkTitle>{title}</LinkTitle> */}
      <LinksList>
      
        {links.forEach(function (obj) {
          return(
          <LinkItem key={obj.index}>{obj.link}</LinkItem>
          )
        })}
      </LinksList>
    </LinksSection>
  );
}

const LinksSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: auto 0;
`;

const LinkTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: #fff;
`;

const LinksList = styled.div`
  align-self: start;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  justify-content: start;
  gap: 10px;
  color: #fff;
`;

const LinkItem = styled.a`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
