import * as React from "react";
import styled from "styled-components";
import { DocsCard } from "./DocsCard";

export function DocsContainer() {
  const documents = [
    {
      id: 1,
      title: "European Direct Selling annual report 2009-10 (pdf)",
      link: "https://websites.godaddy.com/blob/a1731ca6-8450-4675-8a85-71ec448c9134/downloads/1bf8344ds_733346.pdf?d240b656?ver=1619466603083",
    },
    {
      id: 2,
      title: "DSA State of the Industry 2015 (pptx)",
      link: "https://websites.godaddy.com/blob/a1731ca6-8450-4675-8a85-71ec448c9134/downloads/1bf83arrt_946277.pptx?18d1177f?ver=1619466603083",
    },
    {
      id: 3,
      title: "DSN USA Today DSN Preview(pdf)",
      link: "https://websites.godaddy.com/blob/a1731ca6-8450-4675-8a85-71ec448c9134/downloads/1bf83cpj3_756196.pdf?244178d1?ver=1619466603083",
    },
    {
      id: 4,
      title: "DSN WSJ Book preview (pdf)",
      link: "https://websites.godaddy.com/blob/a1731ca6-8450-4675-8a85-71ec448c9134/downloads/1bf83fohr_745414.pdf?0a610a3e?ver=1619466603083",
    },
    {
      id: 5,
      title: "DSN TOP 100 2016 (pdf)",
      link: "https://websites.godaddy.com/blob/a1731ca6-8450-4675-8a85-71ec448c9134/downloads/1bf83q0dm_842801.pdf?a0c6f461?ver=1619466603083",
    },
  ];

  return (
    <GridContainer>
      {documents.map((doc) => (
        <DocsCard key={doc.id} title={doc.title} link={doc.link} />
      ))}
    </GridContainer>
  );
}

const GridContainer = styled.div`
     display: grid
;
    overflow: scroll;
    grid-template-columns: repeat(10, 1fr);
    gap: 24px;
    padding: 24px;
    max-width: 1200px;
    margin: 0 auto;
}

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
